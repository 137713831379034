import Vue from "vue";
import vSelect from "vue-select";
import {loadMap, getMapKey} from "./maptiler";

Vue.component("v-select", vSelect);

window.listComponent = null;
window.setVue = function(vuecomponent) {
  window.listComponent = vuecomponent;
}

if(document.getElementById('app-list')) {
  var app = new Vue({
    el: '#app-list',
    data: {
      settings: {
        url: 'https://api.azotopower.pl',
        key: '5fggm-ds7nfe-5553gj-q3hbo9',
        // url: 'https://api.local',
        // key: 'hru5o-jds6ew-pp8utd-afcg44',
      },

      cities: [],
      list: [],
      filteredAddresses: [],
      distances: [],

      city: null,
      department: true,
      distribution: true,
      showPreloader: true,
      showPagination: false,
      distance: {label: '+ 15 km', code: 15},

      // pagination vars
      currentPage: 1,
      totalPages: 1,
      perPage: 9,
      indexOfLastOffer: 1,
      indexOfFirstOffer: 1,
      currentAddresses: [],
      showPages: [],
    },
    created: function () {
      setVue(this);
    },
    mounted: function(){
      this.distances = this.distanceGenerator();
      // setTimeout(this.getList, 5000);
      this.getList();
    },
    watch: {
      city: function (newVal, oldVal) {
        this.selected();
      },
      distance: function (newVal, oldVal) {
        if (this.city) {
          this.selected();
        }
      },
      department: function (newVal, oldVal) {
        if (newVal === false) {
          this.distribution = true;
        }
        this.selected();
      },
      distribution: function (newVal, oldVal) {
        if (newVal === false) {
          this.department = true;
        }
        this.selected();
      }
    },
    methods: {
      searchLocations: function (search, loading) {
        clearTimeout(this.timeout);

        if (!search.length) {
          loading(false);
          return;
        }

        loading(true);

        let that = this;
        this.timeout = setTimeout(() => {
          that.request("/api/locations/search?search=" + search, function (data) {
            that.cities = data;
            loading(false);
          }, false, true);
        }, 300);
      },
      distanceGenerator: function () {
        return [
          {label: '+ 15 km', code: 15},
          {label: '+ 30 km', code: 30},
          {label: '+ 45 km', code: 45},
          {label: '+ 60 km', code: 60},
          {label: '+ 75 km', code: 75},
          {label: '+ 90 km', code: 90},
        ];
      },
      runGeolocation: function () {
        if (this.remote) {
          return;
        }
        try {
          navigator.geolocation.getCurrentPosition((position) => {
            let that = this;
            this.request(
              "https://api.maptiler.com/geocoding/" + position.coords.longitude +"," + position.coords.latitude + ".json?language=pl&key=" + getMapKey(),
              function (data) {
                let place = data.features.find((item) => item.place_type[0] === "place");
                let municipality = data.features.find((item) => item.place_type[0] === "municipality");
                let county = data.features.find((item) => item.place_type[0] === "county");
                let state = data.features.find((item) => item.place_type[0] === "state");

                that.city = {
                  "city": place ? place.text : null,
                  "province": state ? state.text : null,
                  "powiat": county ? county.text : null,
                  "gmina": municipality ? municipality.text : null,
                  "latitude": position.coords.latitude,
                  "longitude": position.coords.longitude
                }

              }, true);

          }, (error) => {
            // console.log(error);
          });
        } catch (e) {
          // console.log(e);
        }
      },
      selected: function () {

        let that = this;
        this.filteredAddresses = this.list.filter(function (item) {

          if (!that.department || !that.distribution) {
            if (that.department &&  item[7] !== 'Oddział PROCAM') {
              return false;
            }
            if (that.distribution &&  item[7] !== 'Punkt Dystrybucji') {
              return false;
            }
          }
          if (that.city && that.distance) {
            if (isNaN(item[9]) || isNaN(item[10])) {
              return true;
            }
            const dist = that.distanceCalc(item[9], item[10], that.city.latitude, that.city.longitude);
            return dist <= that.distance.code;
          }
          return true;
        })
        this.currentPage = 1;
        this.reloadPagination();
        setTimeout(function () {loadMap(false, that.filteredAddresses, that.city ? that.city.latitude : null, that.city ? that.city.longitude : null, true)});
      },
      clearForm: function () {
        this.city = null;
        this.department = true;
        this.distribution = true;
        this.distance = {label: '+ 15 km', code: 15};
      },
      getList: function () {
        let that = this;
        setTimeout(function () {

          that.request("/api/google/sheets/4/" + that.settings.key, function( data ) {
            that.currentPage = 1;
            that.setCurrentPage(that.currentPage);
            data.shift();
            //tylko procam
            that.list = data.sort(function (a,b) {return a[7].localeCompare(b[7])}).filter(function (item) {
              return item[7] === "Oddział PROCAM"
            });

            that.filteredAddresses = that.list;
            that.reloadPagination();
            setTimeout(function () {loadMap(false, that.list, null, null, true)});
          });
        })
      },
      reloadPagination: function() {
        this.totalPages = Math.ceil(this.filteredAddresses.length / this.perPage);
        this.indexOfLastOffer = this.currentPage * this.perPage;
        this.indexOfFirstOffer = this.indexOfLastOffer - this.perPage;
        this.currentAddresses = this.filteredAddresses.slice(this.indexOfFirstOffer, this.indexOfLastOffer);

        if (this.totalPages < 6) {
          this.showPages = this.range(1, this.totalPages);
        } else if (this.currentPage - 2 > 0 && this.currentPage + 2 < this.totalPages) {
          this.showPages = this.range(this.currentPage - 2, this.currentPage + 2);
        } else if (this.currentPage - 2 < 1) {
          this.showPages = this.range(1, 5);
        } else {
          this.showPages = this.range(this.totalPages - 4, this.totalPages);
        }

        this.showPagination = this.filteredAddresses.length > this.perPage;
      },
      range: function (from, to) {
        let arr = [];
        for(let i = from; i <= to; ++i) {
          arr.push(i);
        }
        return arr;
      },
      setCurrentPage: function(page) {
        this.currentPage = page;
        this.reloadPagination();
      },
      nextPage: function() {
        if(this.currentPage < this.totalPages) {
          this.setCurrentPage(this.currentPage + 1);
        }
      },
      prevPage: function() {
        if(this.currentPage > 1) {
          this.setCurrentPage(this.currentPage - 1);
        }
      },
      setDepartment: function(value) {
        this.department = value;
        this.distribution = !value;
      },
      request: function (url, callback) {
        var request = new XMLHttpRequest();
        var that = this;
        request.onreadystatechange = function() {
          if(request.readyState === 4 && request.status === 200) {
             that.showPreloader = false;
          } else {
            // that.showPreloader();
          }
        }

        request.open('GET', this.settings.url + url, true);

        request.onload = function() {
          if (this.status >= 200 && this.status < 400) {
            callback(JSON.parse(this.response))
          }
        };
        request.send();
      },
      distanceCalc: function (lat1, lon1, lat2, lon2) {
        let radlat1 = Math.PI * lat1 / 180
        let radlat2 = Math.PI * lat2 / 180
        let theta = lon1 - lon2
        let radtheta = Math.PI * theta / 180
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist)
        dist = dist * 180 / Math.PI
        dist = dist * 60 * 1.1515
        return dist * 1.609344
      }
    }
  })
}
